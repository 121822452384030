import React from 'react';
import Twitter from "../../assets/social-media/x.svg"
import Youtube from "../../assets/social-media/youtube.svg"
import Linkedin from "../../assets/social-media/linkedin.svg"
import Facebook from "../../assets/social-media/facebook.svg"
import {Trans} from "react-i18next";
import {Link} from "gatsby";


const SocialMedia = (props: any) => {
  const currentYear = new Date().getFullYear();
  return (
      <div {...props}>
      <div className="md:flex md:items-center md:justify-between" >
        <div className="md:order-2">
          <ul className="flex flex-wrap gap-6">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61559566055293"
                 target="_blank"
                 rel="me external nofollow noopener noreferrer"
                 title="Follow us on Facebook"
                 className="text-slate-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200">
                <span className="sr-only">Facebook</span>
                <Facebook className="h-5 w-5"></Facebook>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/horarium-ai"
                 target="_blank"
                 rel="me external nofollow noopener noreferrer"
                 title="Follow us on LinkedIn"
                 className="text-slate-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200">
                <span className="sr-only">LinkedIn</span>
                <Linkedin className="h-5 w-5"></Linkedin>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCnHQ2z_VtV1clW0AAu4mpeA" target="_blank"
                 rel="me external nofollow noopener noreferrer"
                 title="Subscribe to our YouTube channel"
                 className="text-slate-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-200">
                <span className="sr-only">YouTube</span>
                <Youtube className="h-5 w-5"></Youtube>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/horariumai" target="_blank"
                 rel="me external nofollow noopener noreferrer"
                 title="Follow us on Twitter"
                 className="text-slate-600 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200">
                <span className="sr-only">Twitter</span>
                <Twitter className="h-5 w-5"></Twitter>
              </a>
            </li>
          </ul>
        </div>
        <p className="text-slate-500 mt-8 text-base font-medium md:order-1 md:mt-0">
          © 2019-{currentYear} Horarium.ai, Softix
        </p>
      </div>
      </div>
  );
}

const ItemLink = ({path, children}: { path: string, children?: any }) => {
  return (
      <>
        {
          path.startsWith('https') ?
              <a href={path}
                 className="text-base text-slate-600 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-200">
                {children}
              </a> :
              <Link to={path}
                    className="text-base text-slate-600 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-200">
                {children}
              </Link>
        }
      </>
  );
}

const Links = ({languagePath, ...props}) => {
  return (
      <div {...props}>
        <div className="xl:grid xl:grid-cols-4 xl:gap-12">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-12 xl:col-span-4 justify-items-center">
            <div className="md:gap-12">
              <div className="">
                <h3 className="text-sm font-semibold uppercase tracking-wider text-slate-700">
                  <Trans>common.resources</Trans>
                </h3>
                <ul className="mt-4 space-y-3">
                  <li>
                    <ItemLink path={`${languagePath}/blog/`}>
                      <Trans>common.blog</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${languagePath}/help/`}>
                      <Trans>common.help</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${languagePath}/general/faq`}>
                      <Trans>footer.faq</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${languagePath}/pricing`}>
                      <Trans>footer.pricing</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/topics/add`}>
                      <Trans>footer.support</Trans>
                    </ItemLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:gap-12">
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider text-dark-blue-25">
                  <Trans>footer.company</Trans>
                </h3>
                <ul className="mt-4 space-y-3">
                  <li>
                    <ItemLink path={`${languagePath}/blog/about-us/`}>
                      <Trans>footer.about</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${languagePath}/testimonials`}>
                      <Trans>common.testimonials</Trans>
                    </ItemLink>
                  </li>
                  {/*<li>*/}
                  {/*  <ItemLink path='https://app.horarium.ai/topics/add'>*/}
                  {/*    <Trans>footer.updates</Trans>*/}
                  {/*  </ItemLink>*/}
                  {/*</li>*/}
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/topics/add`}>
                      <Trans>common.contact-us</Trans>
                    </ItemLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:gap-12">
              <div className="mt-6 md:mt-0">
                <h3 className="text-sm font-semibold uppercase tracking-wider text-dark-blue-25">
                  <Trans>footer.legal</Trans>
                </h3>
                <ul className="mt-4 space-y-3">
                  <li>
                    <ItemLink path="https://app.horarium.ai/legal/terms">
                      <Trans>footer.terms-and-conditions</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path="https://app.horarium.ai/legal/cookies">
                      <Trans>footer.privacy-policy</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path="https://app.horarium.ai/legal/privacy">
                      <Trans>footer.gdpr</Trans>
                    </ItemLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const Footer = ({languagePath, ...props}) => {

  return (
      <div {...props}>
        <div className="px-6 sm:px-0">
          <Links languagePath={languagePath} className="py-12 lg:py-16"></Links>
          <div className="h-px w-full bg-gradient-to-r from-transparent via-primary-600 to-transparent"></div>
          <SocialMedia className="px-5 sm:px-5 lg:px-10 py-3 md:py-5"/>
        </div>
      </div>
  )
}

export default Footer;
